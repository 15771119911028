import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormFeedback,
    Input,
    Form,
    UncontrolledTooltip,
} from "reactstrap";
import { createEventVenue, createOrganizerCoupon, createSpotBooking, deleteSpotBooking, getAllCoupon, getAllEventByOrganizer, getAllEvents, getAllSpotBooking, getAllTicketByEvent, getAllTicketByEventAndVenue, getEventVenuesBySlug, getOrganizerCoupon, updateEventVenue, updateOrganizerCoupon } from "api";
import Select from "react-select";
import Breadcrumbs from "components/Common/Breadcrumb";
import Spinners from "components/Common/Spinner";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from 'react-hot-toast';
import moment from "moment";


function SpotBooking() {
    document.title = "Spot Booking | EventoX";

    const navigate = useNavigate();
    const [coupons, setCoupons] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [ticketList, setTicketList] = useState([])
    const [venueList, setVenueList] = useState([])
    const [selectedVenue, setSelectedVenue] = useState(null)
    const [selectedTicketType, setSelectedTicketType] = useState(null)
    const [eventOptions, setEventOptions] = useState([])
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isVenueExist, setIsVenueExist] = useState(false)
    const [statusOptions, setStatusOptions] = useState([
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Inactive' }
    ])
    const [spotBookingList, setSpotBookingList] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [bookingUrl, setBookingUrl] = useState("")


    const validation = useFormik({
        initialValues: {
            organizerId: "",
            ticketTypeId: "",
            eventId: "",
            venueId: "",
            bookingUrl: "",
            status: 'active'
        },
        validationSchema: Yup.object({
            organizerId: Yup.string().required("Organizer is required"),
            ticketTypeId: Yup.string().required("Ticket is required"),
            eventId: Yup.string().required("Event is required"),
            status: Yup.string().required("Status is required"),

        }),
        onSubmit: async (values) => {
            try {
                if (isEdit) {
                    toast.success("Spot Booking updated successfully");
                    setSelectedStatus(null)
                    setIsEdit(false)
                    toggle();
                } else {
                    console.log("🚀 ~ validation.onSubmit ~ values:", values)
                    const resp = await createSpotBooking(values)
                    setSelectedStatus(null)
                    setIsEdit(false)
                    toggle();
                    toast.success("Spot Booking created successfully");

                }
                fetchAll();
            } catch (error) {
                toast.error("Something went wrong");
                console.log(error);
            }
        }
    })



    useEffect(() => {

        fetchAll();
        return () => {

        }
    }, [])
    const toggle = () => {
        setModal(!modal);
        validation.resetForm();
    };

    const fetchAll = async () => {
        try {
            setIsLoading(true)
            const respAllEvent = await getAllEvents();
            const respAllSpotBooking = await getAllSpotBooking();
            setSpotBookingList(respAllSpotBooking)
            const eventOptions = respAllEvent.map((item) => {
                return {
                    value: item.eventId,
                    label: item.eventName,
                    slug: item.slugId,
                    eventData: item
                }
            })
            setEventOptions(eventOptions);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }
    const handleEventSelection = async (data) => {
        const slugId = data.slug
        const respVenue = await getEventVenuesBySlug(slugId)
        const respTicket = await getAllTicketByEvent(slugId)
        setSelectedTicketType("")
        setSelectedVenue("")
        if (respVenue.length > 0) {
            setIsVenueExist(true)
            const venueOptions = respVenue.map((item) => {
                return {
                    value: item.venueId,
                    label: item.venueName,
                }
            })
            setVenueList(venueOptions)
        } else {
            setIsVenueExist(false)
            setVenueList([])
        }
        if (respTicket.length > 0) {
            const ticketOptions = respTicket.map((item) => {
                return {
                    value: item.ticketTypeId,
                    label: `${item.ticketType} (${item.ticketStatus})`,
                    venueId: item.venueId
                }
            })
            setTicketList(ticketOptions)
        } else {
            setTicketList([])
        }

        setSelectedEvent(data)
        validation.setValues({ ...validation.values, eventId: data.value, organizerId: data.eventData.organizerId })
    }
    const handleVenueSelection = async (data) => {
        setSelectedVenue(data)
        setSelectedTicketType("")
        const ticketResp = await getAllTicketByEventAndVenue(selectedEvent.slug, data.value)
        if (ticketResp.length > 0) {
            const ticketOptions = ticketResp.map((item) => {

                return {
                    value: item.ticketTypeId,
                    label: `${item.ticketType} (${item.ticketStatus})`,
                }

            })
            setTicketList(ticketOptions)
        } else {
            setTicketList([])
        }
        validation.setValues({ ...validation.values, venueId: data.value })

    }

    const handleGoToUrl = (data) => {
        console.log("🚀 ~ handleGoToUrl ~ data:", data)
        const url = `https://eventox.co/booking/spot-booking/${data.spotBookingId}`
        setBookingUrl(url)
        window.open(url, '_blank')
    }
    const onClickDelete = async (data) => {
        console.log("🚀 ~ onClickDelete ~ data:", data)
        const deleteResp = await deleteSpotBooking(data.spotBookingId)
        toast.success("Spot Booking deleted successfully")
        fetchAll()
    }
    const columns = useMemo(
        () => [

            {
                header: 'Organizer',
                accessorKey: 'Organizer.name',
                enableColumnFilter: false,
                enableSorting: true,

            },

            {
                header: 'Event',
                accessorKey: 'Event.eventName',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                    //if discount type is percentage
                    if (cell.row.original.discountType === 'percentage') {
                        return <span>{cell.getValue()}%</span>
                    } else {
                        return <span>{cell.getValue()}</span>
                    }
                }
            },
            {
                header: 'Ticket',
                accessorKey: 'TicketType.ticketType',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                headerName: 'Venue',
                accessorKey: 'Venue.venueName',
                enableColumnFilter: false,
                enableSorting: true,
            },

            {
                header: 'Created At',
                accessorKey: 'createdAt',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                    return <span>{moment(new Date(cell.getValue())).format('DD-MM-YYYY HH:mm:ss')}</span>
                }
            },
            {
                header: 'Status',
                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cell) => {
                    return (
                        <div>
                            {
                                cell.getValue() === 'active' ? (
                                    <span className="badge badge-soft-primary font-size-11 m-1">{cell.getValue()}</span>
                                ) : (
                                    <span className="badge badge-soft-danger font-size-11 m-1">{cell.getValue()}</span>
                                )
                            }

                        </div>
                    );
                },
            },


            {
                header: 'Action',
                cell: (cellProps) => {
                    return (
                        <UncontrolledDropdown>
                            <DropdownToggle tag="a" className="card-drop">
                                <i className="mdi mdi-dots-horizontal font-size-18"></i>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-end">



                                <DropdownItem
                                    onClick={() => {
                                        const data = cellProps.row.original;
                                        onClickDelete(data);
                                    }}>
                                    <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                                    Delete
                                    <UncontrolledTooltip placement="top" target="deletetooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </DropdownItem>
                                <DropdownItem onClick={() => {
                                    handleGoToUrl(cellProps.row.original)
                                }}>
                                    <i className="mdi mdi-pencil font-size-16 text-primary me-1" id="deletetooltip"></i>
                                    Go To URL
                                </DropdownItem>

                            </DropdownMenu>

                        </UncontrolledDropdown>
                    );
                }
            },
        ],
        []
    );

    const handleClickNew = () => {
        toggle();
    }

    //meta title
    document.title = "Spot Booking | EventoX";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Spot Booking" />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setIsLoading} />
                                :
                                <Col lg="12">

                                    <Card>
                                        <CardBody>

                                            <Row>
                                                <TableContainer
                                                    columns={columns}
                                                    data={spotBookingList || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    SearchPlaceholder="Search..."
                                                    isCustomPageSize={true}
                                                    isAddButton={true}
                                                    handleUserClick={handleClickNew}
                                                    buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                                                    buttonName="New Spot Booking"
                                                    tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    pagination="pagination"
                                                />
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </Container>
            </div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} tag="h4">{!!isEdit ? "Edit Spot Booking" : "Add New Spot Booking"}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={e => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                        <Row>
                            <Col xs={12}>

                                <div className="mb-3">
                                    <Label className="form-label">Select Event</Label>
                                    <Select
                                        value={selectedEvent}
                                        onChange={handleEventSelection}
                                        options={eventOptions}
                                        className="select2-selection"
                                        menuContainerStyle={{ 'zIndex': 999 }}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                    {validation.touched.eventId && validation.errors.eventId ? <p className="error-message">{validation.errors.eventId}</p> : null}
                                </div>
                                {isVenueExist ?
                                    <div className="mb-3">
                                        <Label className="form-label">Select Venue</Label>
                                        <Select
                                            value={selectedVenue}
                                            onChange={handleVenueSelection}
                                            options={venueList}
                                            className="select2-selection"
                                            menuContainerStyle={{ 'zIndex': 999 }}
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 9999 })
                                            }}
                                        />
                                        {validation.touched.venueId && validation.errors.venueId ? <p className="error-message">{validation.errors.venueId}</p> : null}
                                    </div>
                                    : null}

                                <div className="mb-3">
                                    <Label className="form-label">Select Ticket</Label>
                                    <Select
                                        value={selectedTicketType}
                                        onChange={(data) => {
                                            setSelectedTicketType(data)
                                            validation.setValues({ ...validation.values, ticketTypeId: data.value })
                                        }}
                                        options={ticketList}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                    {validation.touched.ticketTypeId && validation.errors.ticketTypeId ? <p className="error-message">{validation.errors.ticketTypeId}</p> : null}
                                </div>



                                <div className="mb-3">
                                    <Label className="form-label">Status</Label>
                                    <Select
                                        value={selectedStatus}
                                        onChange={(data) => {
                                            setSelectedStatus(data)
                                            validation.setValues({ ...validation.values, status: data.value })
                                        }}
                                        options={statusOptions}
                                        styles={{
                                            menu: provided => ({ ...provided, zIndex: 9999 })
                                        }}
                                    />
                                    {validation.touched.status && validation.errors.status ? <p className="error-message">{validation.errors.status}</p> : null}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <button type="submit" className="btn btn-success save-user">Submit</button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
}

export default SpotBooking
