import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    UncontrolledDropdown
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import { getAllEventByOrganizer, getEventVenuesBySlug, getTicketSummery } from "api";
import { AgGridReact } from "ag-grid-react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
const TicketSummery=()=>{
    const [eventList, setEventList] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [getVenueList, setGetVenueList] = useState([])
    const [selectedVenue, setSelectedVenue] = useState(null)
    const [ticketSummeryList, setTicketSummeryList] = useState([])
    useEffect(() => {
        fetchAll()
    }, [])
    

    const fetchAll = async () => {
        try {

            const respAllEvent = await getAllEventByOrganizer();
            const eventOptions = respAllEvent.map((item) => {
                return {
                    value: item.eventId,
                    label: item.eventName,
                    slugId: item.slugId
                }
            })
            setEventList(eventOptions);





        } catch (error) {
            console.log(error);
        }
    }
    const fetchVenueByEvent = async (eventData) => {
        const getTicketSummeryResp  = await getTicketSummery(eventData.value)
        setTicketSummeryList(getTicketSummeryResp)
        const getVenueList = await getEventVenuesBySlug(eventData.slugId)
        const venueObj={
            value: 0,
            label: "All Venues"
        }
        const venueOptions = getVenueList.map((item) => {
            return {
                value: item.venueId,
                label: item.venueName
            }
        })
        setGetVenueList([venueObj, ...venueOptions])

       


    }
    const fetchBookingByEventVenue = async (venueId) => {
        const eventId=selectedEvent.value
        const getTicketSummeryResp = await getTicketSummery(eventId)
        if(venueId==0){
            setTicketSummeryList(getTicketSummeryResp)
        }else{
           
            const filterTicketSummery = getTicketSummeryResp.filter((item)=>item.TicketType.venueId==venueId)
            setTicketSummeryList(filterTicketSummery)
        }
       
    }
    // {
    //     "totalTicketCount": "18",
    //     "totalTicketAmount": 133,
    //     "discountAmount": 325,
    //     "TicketType": {
    //         "ticketTypeId": "14",
    //         "ticketType": "Early Bird",
    //         "price": 25,
    //         "maxCount": "25",
    //         "ticketStatus": "sold-out",
    //         "currentCount": "18",
    //         "createdAt": "2024-10-03T19:25:22.645Z",
    //         "updatedAt": "2024-10-03",
    //         "eventSlug": "pakka-local-chapter-2",
    //         "ticketOrder": "1",
    //         "venueId": "169",
    //         "groupTicketPrice": null,
    //         "isGroupTicket": false,
    //         "groupTicketCount": "0"
    //     }
    // }
    const columns = useMemo(
        () => [
            {
                headerName: 'Ticket Type',
                field: 'TicketType.ticketType',
                width: 300
            },
            {
                headerName: 'Bookings',
                field: 'totalBooking',
                width: 100
            },
            
            {
                headerName: 'Ticket Sold',
                field: 'totalTicketCount',
                width: 130
            },
            {
                headerName: 'Ticket Price',
                cellRenderer: (params) => {
                    return `$${params.data.TicketType.price.toFixed(2)}`
                },
            },
            {
                headerName: 'Ticket Sales',
                cellRenderer: (params) => {
                    if(params.data.TicketType.isGroupTicket){
                        return `$${(params.data.totalBooking*params.data.TicketType.price).toFixed(2)}`
                    }else{
                        return `$${(params.data.TicketType.price*params.data.totalTicketCount).toFixed(2)}`
                    }
                },
            },
            {
                headerName: 'Discount',
                field: 'discountAmount',
                cellRenderer: (params) => {
                    return `$${params.data.discountAmount.toFixed(2)}`
                },
            },
          
           
            {
                headerName: 'Total',
                cellRenderer: (params) => {
                    if(params.data.TicketType.isGroupTicket){
                        return `$${(params.data.totalBooking*params.data.TicketType.price - params.data.discountAmount).toFixed(2)}`
                    }else{
                        return `$${(params.data.TicketType.price*params.data.totalTicketCount - params.data.discountAmount).toFixed(2)}`
                    }
                },
            }



        ],
        []
    )
    const downloadCSV = () => {
        if (ticketSummeryList.length > 0) {
            const csvRows = [];
            // Get the headers
            const headers=['Ticket Type','Bookings','Ticket Sold','Ticket Price','Ticket Sales','Discount','Total']
            csvRows.push(headers.join(','));


            // Get the data
            for (const row of ticketSummeryList) {
                const values=headers.map((header)=>{
                    if(header=="Ticket Type"){
                        return row.TicketType.ticketType
                    }
                    if(header=="Bookings"){
                        return row.totalBooking
                    }
                    if(header=="Ticket Sold"){
                        return row.totalTicketCount
                    }
                    if(header=="Ticket Price"){
                        return `$${row.TicketType.price.toFixed(2)}`
                    }
                    if(header=="Ticket Sales"){
                        if(row.TicketType.isGroupTicket){
                            return `$${(row.totalBooking*row.TicketType.price).toFixed(2)}`
                        }else{
                            return `$${(row.TicketType.price*row.totalTicketCount).toFixed(2)}`
                        }
                    }
                    if(header=="Discount"){
                        return `$${row.discountAmount.toFixed(2)}`
                    }
                    if(header=="Total"){
                        if(row.TicketType.isGroupTicket){
                            return `$${(row.totalBooking*row.TicketType.price - row.discountAmount).toFixed(2)}`
                        }else{
                            return `$${(row.TicketType.price*row.totalTicketCount - row.discountAmount).toFixed(2)}`
                        }
                    }
                })
                csvRows.push(values.join(','));
            }

            // Create a blob and download
            const csvString = csvRows.join('\n');
            const blob = new Blob([csvString], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('href', url);
            a.setAttribute('download', 'ticket_summary.csv');
            a.click();
            URL.revokeObjectURL(url); // Clean up
        }
    };
    const downloadPDF = () => {
        if (ticketSummeryList.length > 0) {
            const doc = new jsPDF('l', 'mm', 'a4');
            const headers = ['Ticket Type', 'Bookings', 'Ticket Sold', 'Ticket Price', 'Ticket Sales', 'Discount', 'Total'];

            // Add headers
            doc.autoTable({
                head: [headers],
                body: ticketSummeryList.map(row => {
                    if(row.TicketType.isGroupTicket){
                        return [
                            row.TicketType.ticketType,
                            row.totalBooking,
                            row.totalTicketCount,
                            `$${row.TicketType.price.toFixed(2)}`,
                            `$${(row.totalBooking*row.TicketType.price).toFixed(2)}`,
                            `$${row.discountAmount.toFixed(2)}`,
                            `$${(row.totalBooking*row.TicketType.price - row.discountAmount).toFixed(2)}`
                        ]
                    }else{
                        return [
                            row.TicketType.ticketType,
                            row.totalBooking,
                            row.totalTicketCount,
                            `$${row.TicketType.price.toFixed(2)}`,
                            `$${(row.TicketType.price*row.totalTicketCount).toFixed(2)}`,
                            `$${row.discountAmount.toFixed(2)}`,
                            `$${(row.TicketType.price*row.totalTicketCount - row.discountAmount).toFixed(2)}`
                        ]
                    }
                }),
            });

            doc.save('ticket_summary.pdf');
        }
    };
    return(
        <React.Fragment>
        <div className="page-content">
            <Container fluid>
                {/* Render Breadcrumb */}
                <Breadcrumbs
                    title={"Ticket Sale"}
                    breadcrumbItem={"Ticket Sale"}
                />
                <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xl="6">
                                            <div className="col-md-12">
                                                <Select
                                                    value={selectedEvent}
                                                    placeholder="Select Event"
                                                    onChange={(data) => {
                                                        setSelectedEvent(data)
                                                        fetchVenueByEvent(data)
                                                    }}
                                                    options={eventList}
                                                    width="100%"
                                                    className="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                        <Col xl="6">
                                            <div className="col-md-12">
                                                <Select
                                                    value={selectedVenue}
                                                    placeholder="Select Event"
                                                    onChange={(data) => {
                                                        setSelectedVenue(data)
                                                        fetchBookingByEventVenue(data.value)
                                                    }}
                                                    options={getVenueList}
                                                    width="100%"
                                                    className="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                                       
                                        <Col xl="12">
                                            <div className="text-lg-end mt-3 mt-lg-0">
                                                <UncontrolledDropdown
                                                >
                                                    <DropdownToggle color="primary" type="button">
                                                        <i className="bx bx-download me-2"></i> Export <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>

                                                        <DropdownItem href="#" onClick={downloadCSV}>Export CSV</DropdownItem>
                                                        <DropdownItem href="#" onClick={downloadPDF}>Export PDF</DropdownItem>

                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                               
                                            </div>
                                        </Col>

                                    </Row>
                    <Row>
                        <div style={{
                            height: '500px',
                            width: '100%'
                        }} className="ag-theme-quartz">
                            <AgGridReact
                                columnDefs={columns}
                                rowData={ticketSummeryList}
                            />
                        </div>
                    </Row>
                </Container>
            </div>
        </React.Fragment>

    )
}
export default TicketSummery;