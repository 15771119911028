import moment from "moment";
import apiClient from "./apiClient";
import { saveAs } from 'file-saver';


/** Events Section */
/**
 * Fetches all events.
 * @param {number} id - The user ID.
 * @returns {Promise<Object>} - The user object.
 */
export const getAllEvents = async () => {
    const response = await apiClient.get(`/events`);
    return response.data;
};

export const getEventBySlug = async (slug) => {
    const response = await apiClient.get(`/events/${slug}`);
    return response.data;
};

export const getEventById = async (id) => {
    const response = await apiClient.get(`/events/event-by-id/${id}`);
    return response.data;
};
//update event
export const updateEvent = async (id,data) => {
    const response = await apiClient.put(`/events/${id}`,data);
    return response.data;
};

/** Ticket Section */

/**
 * Fetches all tickets by slug.
 * @param {string} slug - The user ID.
 * @returns {Promise<Object>} - The user object.
 */

export const getAllTicketsBySlug = async (slug) => {
    const response = await apiClient.get(`/ticket-type/${slug}`);
    return response.data;
};


/** Booking  Section */

export const createBooking = async (data) => {
    const response = await apiClient.post('/booking', data);
    return response.data;
};

export const getBookingPayment = async (bookingNo) => {
    const response = await apiClient.get(`/booking/payment/${bookingNo}`);
    return response.data;
}

export const getBookingDetails = async (bookingNo) => {
    const response = await apiClient.get(`/booking/${bookingNo}`);
    return response.data;
}

export const sendEmailConfirmation = async (bookingNo) => {
    const response = await apiClient.get(`/booking/send-confirmation/${bookingNo}`);
    return response.data;
}

export const updateBookingConfirmation = async (bookingId,data) => {
    const response = await apiClient.put(`/booking/${bookingId}`,data);
    return response.data;
}



//Admin API

export const getAllOrganizer = async () => {
    const response = await apiClient.get(`/organizer`);
    return response.data;
}


export const getAllBookingByEvent = async (slugId) => {
    const response = await apiClient.get(`/booking/event/${slugId}`);
    return response.data;
}
export const getAllBookingByOrganizer = async () => {
    const response = await apiClient.get(`/booking/organizer`);
    return response.data;
}

//get all event by event id
export const getBookingByEventId = async (eventId) => {
    const response = await apiClient.get(`/booking/booking-by-event/${eventId}`);
    return response.data;
}
//get all booking by venue id and event id
export const getBookingByVenueIdAndEventId = async (venueId,eventId) => {
    const response = await apiClient.get(`/booking/booking-by-event-and-venue/${eventId}/${venueId}`);
    return response.data;
}
// Get all countries
export const getAllCountries = async () => {
    const response = await apiClient.get('/countries');
    return response.data;
};

//create Organizer

export const createOrganizer = async (data) => {
    const response = await apiClient.postForm('/users',data);
    return response.data;
};
//Update user
export const updateUser = async (id,data) => {
    const response = await apiClient.put(`/users/${id}`,data);
    return response.data;
};
//delete user
export const deleteUser = async (id) => {
    const response = await apiClient.delete(`/users/${id}`);
    return response.data;
};


//get all event by organizer
export const getAllEventByOrganizer = async () => {
    const response = await apiClient.get(`/events/organizer`);
    return response.data;
}

//get all event category 
export const getAllEventCategory = async () => {
    const response = await apiClient.get(`/categories`);
    return response.data;
}

//create event
export const createEvent = async (data) => {
    const response = await apiClient.postForm('/events',data);
    return response.data;
};
//delete event
export const deleteEvent = async (id) => {
    const response = await apiClient.delete(`/events/${id}`);
    return response.data;
};

//get event by query
export const getEventByQuery = async (filedName,filedValue) => {
    const response = await apiClient.get(`/events/query/${filedName}/${filedValue}`);
    return response.data;
}
//get all ticket by event
export const getAllTicketByEvent = async (eventId) => {
    const response = await apiClient.get(`/ticket-type/${eventId}`);
    return response.data;
}
//create ticket
export const createTicket = async (data) => {
    const response = await apiClient.post('/ticket-type',data);
    return response.data;
};

//delete ticket
export const deleteTicket = async (id) => {
    const response = await apiClient.delete(`/ticket-type/${id}`);
    return response.data;
};
//update ticket
export const updateTicket = async (id,data) => {
    const response = await apiClient.put(`/ticket-type/${id}`,data);
    return response.data;
};

//fetch forum by slug
export const getForumBySlug = async (slug) => {
    const response = await apiClient.get(`/form/forms/${slug}`);
    return response.data;
}


export const downloadBookingDetails = async (eventId,venueId) => {
    try {
        const response = await apiClient.post(`/export/export-by-event/${eventId}/${venueId}`,{},{responseType: 'blob'});
       

    const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const saveFileName = `bookings-${moment(new Date()).format('DD-MMM-YYYY')}.xlsx`;
    saveAs(blob, saveFileName);
} catch (error) {
        console.log(error);
}
}
export const downloadBookingDetailsPdf = async (eventId,venueId) => {
    try {
        const response = await apiClient.post(`/export/export-by-event-pdf/${eventId}/${venueId}`,{},{responseType: 'blob'});
        const blob = new Blob([response.data], {
        type: 'application/pdf',
    });
    const saveFileName = `bookings-${moment(new Date()).format('DD-MMM-YYYY')}.pdf`;
    saveAs(blob, saveFileName);
} catch (error) {
    console.log(error);
}
}


export const getEventVenuesBySlug=async(slug) => {   
    const response = await apiClient.get(`/event-venue/${slug}`);
    return response.data;

}

export const getAllTicketByEventAndVenue = async (eventId,venueId) => {
    const response = await apiClient.get(`/ticket-type/ticket-by-venue-event/${venueId}/${eventId}`);
    return response.data;
}   

export const createEventVenue = async (data) => {
    const response = await apiClient.post('/event-venue',data);
    return response.data;
}

export const updateEventVenue = async (id,data) => {
    const response = await apiClient.put(`/event-venue/${id}`,data);
    return response.data;
}



//get all coupon
export const getAllCoupon = async () => {
    const response = await apiClient.get(`/coupon`);
    return response.data;
}
//get organizer coupon
export const getOrganizerCoupon = async () => {
    const response = await apiClient.get(`/coupon/organizer`);
    return response.data;
}
//create admin coupon
export const createAdminCoupon = async (data) => {
    const response = await apiClient.post('/coupon',data);
    return response.data;
}
//create organizer coupon
export const createOrganizerCoupon = async (data) => {
    const response = await apiClient.post('/coupon/organizer',data);
    return response.data;
}
//update organizer coupon
export const updateOrganizerCoupon = async (id,data) => {
    const response = await apiClient.put(`/coupon/${id}`,data);
    return response.data;
}

//spot booking
export const createSpotBooking = async (data) => {
    const response = await apiClient.post('/spot-booking',data);
    return response.data;
}

export const getAllSpotBooking = async () => {
    const response = await apiClient.get(`/spot-booking`);
    return response.data;
}
export const deleteSpotBooking = async (id) => {
    const response = await apiClient.delete(`/spot-booking/${id}`);
    return response.data;
}

export const getTicketSummery = async (eventId) => {
    const response = await apiClient.get(`/booking/ticket-sales-by-event/${eventId}`);
    return response.data;
}