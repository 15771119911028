import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next";
import { getAllEventByOrganizer, getAllEvents, getAllOrganizer, getBookingByEventId, getForumBySlug } from "api";
import Breadcrumbs from "components/Common/Breadcrumb";
import Select from "react-select";
import TableContainer from "../../../components/Common/TableContainer";
import moment from "moment";

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

const pagination = true;
const paginationPageSize = 500;
const paginationPageSizeSelector = [200, 500, 1000];
const EventSummary = props => {
    //meta title
    document.title = "Booking List | EventoX";
    const [eventList, setEventList] = useState([])
    const [selectedEvent, setSelectedEvent] = useState(null)
    const [bookingList, setBookingList] = useState([])
    const [reports, setReports] = useState([
        { title: "Total Booking", iconClass: "bx-copy-alt", description: 0 },
        { title: "Total Ticket Purchased", iconClass: "bx-archive-in", description: 0 },
        {
            title: "Total Revenue",
            iconClass: "bx-purchase-tag-alt",
            description: 0,
        },
    ])

    useEffect(() => {
        fetchAll()
    }, [])

    const fetchAll = async () => {
        try {

            const respAllEvent = await getAllEventByOrganizer();
            const eventOptions = respAllEvent.map((item) => {
                return {
                    value: item.eventId,
                    label: item.eventName,
                    ticketFee: item.ticketFee,
                }
            })
            setEventList(eventOptions);





        } catch (error) {
            console.log(error);
        }
    }

    const fetchBookingByEvent = async (data) => {
        const eventId = data.value;
        const getBookingResp = await getBookingByEventId(eventId)
        console.log("🚀 ~ fetchBookingByEvent ~ getBookingResp", selectedEvent)
        const activeBooking = getBookingResp.filter((item) => item.bookingStatus === 'confirmed');
        const ticketPurchased = activeBooking.filter((item) => item.bookedBy === "0")
        const totalRevenue = ticketPurchased.reduce((acc, item) => acc + item.totalTicketAmount, 0)
        const grandTotalRevenue = ticketPurchased.reduce((acc, item) => acc + item.grandTotal, 0)
        const totalDiscount = ticketPurchased.reduce((acc, item) => acc + item.discountAmount, 0)
        const processingFee = ticketPurchased.reduce((acc, item) => acc + item.processingFee, 0)
        const ticketFee= parseFloat(data.ticketFee)*parseInt(ticketPurchased.length);
        const totalTicketSales= parseFloat(grandTotalRevenue)+parseFloat(totalDiscount);
        const totalFees= parseFloat(processingFee)+parseFloat(ticketFee);
        let summaryData=[
            {
                ticketSold:activeBooking.length,
                ticketSales:`$${(totalTicketSales).toFixed(2)}`,
                discount:`$${(totalDiscount).toFixed(2)}`,
                processingFee:`$${(processingFee).toFixed(2)}`,
                ticketFee:`$${(ticketFee).toFixed(2)}`,
                totalTicketAmount:`$${(totalRevenue).toFixed(2)}`,
               
            }
        ]
        setBookingList(summaryData)
       
      

        console.log("🚀 ~ fetchBookingByEvent ~ getBookingResp:", getBookingResp)

    }
    const columns = useMemo(
        () => [

           

            {
                headerName: 'Ticket Sold',
                field: 'ticketSold',
            },
            {
                headerName: 'Ticket Sales',
                field: 'ticketSales',
                filter: true

            },
            {
                headerName: 'Discount',
                field: 'discount',
                filter: true

            },
            {
                headerName: 'Proc. Fee',
                field: 'processingFee',
                filter: true

            },
            {
                headerName: 'Ticket Fee',
                field: 'ticketFee',
                filter: true

            },
            {
                headerName: 'Total Organizer Revenue',
                field: 'totalTicketAmount',
                filter: true,
                width:250

            },
            
           


        ])
    const sideBar = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            },
        ],
        defaultToolPanel: 'filters',
    };



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title={props.t("Dashboards")}
                        breadcrumbItem={props.t("Dashboard")}
                    />

                   

                    <Row>
                        <Col lg="12">

                            <Card>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col xl="5">
                                            <div className="col-md-12">
                                                <Select
                                                    value={selectedEvent}
                                                    placeholder="Select Event"
                                                    onChange={(data) => {
                                                        setSelectedEvent(data)
                                                        fetchBookingByEvent(data)
                                                    }}
                                                    options={eventList}
                                                    width="100%"
                                                    className="select2-selection"
                                                />

                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div style={{
                                            height: '500px',
                                            width: '100%'
                                        }} className="ag-theme-quartz">
                                            <AgGridReact
                                                columnDefs={columns}
                                                rowData={bookingList}
                                                sideBar={sideBar}
                                                rowGroupPanelShow="always"
                                                pagination={pagination}
                                                paginationPageSize={paginationPageSize}
                                                paginationPageSizeSelector={paginationPageSizeSelector}
                                            />
                                        </div>

                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};



export default withTranslation()(EventSummary);

