import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, UncontrolledTooltip, Input, Label, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// FlatPickr
import "flatpickr/dist/themes/material_blue.css";
import FlatPickr from "react-flatpickr";

import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import SimpleBar from "simplebar-react";
import { createEvent, getAllCountries, getAllEventCategory, getAllOrganizer } from "api";
import Select from "react-select";
import { eventDuration, eventTime } from "utils/constants";
import toast, { Toaster } from 'react-hot-toast';
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const CreateEvent = () => {

  //meta title
  document.title = "Create New Event | Eventox";

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgStore, setImgStore] = useState([]);
  const [dropList, setDropList] = useState(false);
  const [active, setActive] = useState(0)

  const [organizerList, setOrganizerList] = useState([])
  const [categoryList, setCategoryList] = useState([])

  const [selectedOrganizer, setSelectedOrganizer] = useState(null)
  const [selectedEventTime, setSelectedEventTime] = useState(null)
  const [selectedEventDuration, setSelectedEventDuration] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedEventType, setSelectedEventType] = useState(null)
  const [selectedTicketType, setSelectedTicketType] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [otherFeesSelected, setOtherFeesSelected] = useState(true)
  const [selectedThumbImg, setSelectedThumbImg] = useState(null)
  const [selectedBannerImgDesktop, setSelectedBannerImgDesktop] = useState(null)
  const [selectedBannerImgMobile, setSelectedBannerImgMobile] = useState(null)
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [onlineEventCategory, setOnlineEventCategory] = useState(null)
  const [paymentChannel, setPaymentChannel] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [countryList, setCountryList] = useState([])
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [ticketGrouping, setTicketGrouping] = useState(null)
  const [ticketStatus, setTicketStatus] = useState(null)







  useEffect(() => {
    const fetchData = async () => {
      try {
        const resp = await getAllOrganizer();
        const respCategory = await getAllEventCategory();
        const countryResp = await getAllCountries();
        const countryOption = countryResp.map((country, index) => {
          return { value: country.countryId, label: country.countryName }
        })
        setCountryList(countryOption)


        const categoryOption = respCategory.map((cat, index) => {
          return { value: cat.categoryId, label: cat.categoryName }
        })
        const organizerOption = resp.map((org, index) => {
          return { value: org.organizerId, label: org.name }
        });
        setCategoryList(categoryOption)
        setOrganizerList(organizerOption)
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    return () => {

    }
  }, [])

  const handleAcceptedFiles = (files) => {
    const newImages = files?.map((file) => {
      return Object.assign(file, {
        priview: URL.createObjectURL(file),
      })
    })
    setSelectedFiles([...selectedFiles, ...newImages]);
  };

  const onEditorStateChange = (newEditorState) => {

    setEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    validation.setFieldValue('eventDescription', htmlContent)
    console.log("🚀 ~ onEditorStateChange ~ htmlContent:", htmlContent)

    console.log("🚀 ~ onEditorStateChange ~ rawContentState:", rawContentState)

  };
  //  img upload
  const handleImageChange = (event, filed) => {
    if (event.target.files[0].size > 2097152) {
      toast.error("File size should be less than 2MB");
    } else {
      event.preventDefault();
      let reader = new FileReader();
      let file = event.target.files[0];
      reader.onloadend = () => {
        if (filed === 'thumbImg') setSelectedThumbImg(reader.result);
        if (filed === 'bannerImgDesktop') setSelectedBannerImgDesktop(reader.result);
        if (filed === 'bannerImgMobile') setSelectedBannerImgMobile(reader.result);
        // setSelectedImage(reader.result);
        validation.setFieldValue(filed, file)
      };
      reader.readAsDataURL(file);
    }
  };


  const handleClick = (item) => {
    const isItemInImgStore = imgStore.some((imgItem) => imgItem.id === item.id);
    setActive(item.id)
    if (!isItemInImgStore) {
      const newData = [...imgStore, item];
      setImgStore(newData);
      validation.setFieldValue('assignedto', newData)
    } else {
      const newData = imgStore.filter((imgItem) => imgItem.id !== item.id)
      setImgStore(newData);
      validation.setFieldValue('assignedto', newData)
    }
  }

  // validation
  const validation = useFormik({
    initialValues: {
      eventName: '',
      eventDescription: '',
      shortDescription:'',
      thumbImg: '',
      bannerImgDesktop: '',
      bannerImgMobile: '',
      eventLocation: '',
      categoryId: '',
      organizerId: '',
      ticketType: '',
      paymentChannel: '',
      paymentUrl: '',
      eventType: '',
      onlineEventType: '',
      eventStatus: '',
      eventDate: '',
      eventTime: '',
      eventDuration: '',
      createdBy: 'Admin',
      eventVenue: '',
      eventAddress1: '',
      eventAddress2: '',
      eventCountry: 0,
      eventState: '',
      eventCity: '',
      eventZip: '',
      templateName:'',
      processingFee:0,
      ticketFee:0,
      showOtherFees:true,
      ticketGrouping:'single',
      ticketStatus:''




    },
    validationSchema: Yup.object({
      eventName: Yup.string().required("Please Enter Your Event Name"),
      eventDescription: Yup.string().required("Please Enter Your Event Desc"),
      shortDescription: Yup.string().required("Please Enter Your Event Short Desc"),
      thumbImg: Yup.string().required("Please Select Thumb Image"),
      bannerImgDesktop: Yup.string().required("Please Select Banner Image Desktop"),
      bannerImgMobile: Yup.string().required("Please Select Banner Image Mobile"),
      categoryId: Yup.string().required("Please Select Category"),
      organizerId: Yup.string().required("Please Select Organizer"),
      ticketType: Yup.string().required("Please Select Ticket Type"),
      paymentChannel: Yup.string().when('ticketType', {
        is: 'paid',
        then: schema => schema.required("Please Select Payment Channel"),

      }),
      paymentUrl: Yup.string().when('paymentChannel', {
        is: 'external',
        then: schema => schema.required("Please Enter Payment URL")
      }),
      eventType: Yup.string().required("Please Select Event Type"),
      onlineEventType: Yup.string().when('eventType', {
        is: 'online',
        then: schema => schema.required("Please Select Online Event Type")
      }),
      eventStatus: Yup.string().required("Please Select Event Status"),
      eventDate: Yup.string().required("Please Select Event Date"),
      eventTime: Yup.string().required("Please Select Event Time"),
      eventDuration: Yup.string().required("Please Select Event Duration"),
      eventVenue: Yup.string().when('eventType', {
        is: 'offline',
        then: schema => schema.required("Please Enter Event Venue")
      }),

      eventCountry: Yup.string().required("Please Select Event Country"),
      eventLocation: Yup.string().when('eventType', {
        is: 'offline',
        then: schema => schema.required("Please Enter  Event Google Location")
      }),
      templateName: Yup.string().required("Please Select Template"),
      ticketStatus: Yup.string().required("Please Select Ticket Status")




    }),
    onSubmit: async (values) => {
      console.log(values);
      setIsLoading(true);
      let formData = new FormData();
      formData.append("eventName", values.eventName);
      formData.append("eventDescription", values.eventDescription);
      formData.append("shortDescription", values.shortDescription);
      formData.append("thumbImg", values.thumbImg);
      formData.append("bannerImgDesktop", values.bannerImgDesktop);
      formData.append("bannerImgMobile", values.bannerImgMobile);
      formData.append("eventLocation", values.eventLocation);
      formData.append("categoryId", values.categoryId);
      formData.append("organizerId", values.organizerId);
      formData.append("ticketType", values.ticketType);
      formData.append("paymentChannel", values.paymentChannel);
      formData.append("paymentUrl", values.paymentUrl);
      formData.append("eventType", values.eventType);
      formData.append("onlineEventType", values.onlineEventType);
      formData.append("eventStatus", values.eventStatus);
      formData.append("eventDate", values.eventDate);
      formData.append("eventTime", values.eventTime);
      formData.append("eventDuration", values.eventDuration);
      formData.append("createdBy", values.createdBy);
      formData.append("eventVenue", values.eventVenue);
      formData.append("eventAddress1", values.eventAddress1);
      formData.append("eventCountry", values.eventCountry);
      formData.append("templateName", values.templateName);
      formData.append("processingFee", values.processingFee===0?0:values.processingFee);
      formData.append("ticketFee", values.ticketFee===0?0:values.ticketFee);
      formData.append("showOtherFees", values.showOtherFees);
      formData.append("ticketStatus", values.ticketStatus);
      
      selectedFiles.forEach((file) => {
        formData.append("otherImages", file);
      });
      


      console.log(formData);
      try {
        const eventResponse = await createEvent(formData);
        resetFields();
        toast.success(`${values.eventName} event created Successfully`);
      } catch (error) {
        console.log(error);
        toast.error(`Failed to create ${values.eventName} event.${error.message}`);
      } finally {
        setIsLoading(false);
      }

      console.log("🚀 ~ onSubmit:async ~ eventResponse:", eventResponse)

    }
  });
  const resetFields = () => {
    validation.resetForm();
    setSelectedThumbImg(null);
    setSelectedBannerImgDesktop(null);
    setSelectedBannerImgMobile(null);
    setSelectedCategory(null);
    setSelectedOrganizer(null);
    setSelectedTicketType(null);
    setSelectedStatus(null);
    setSelectedEventType(null);
    setPaymentChannel(null);
    setOnlineEventCategory(null);
    setSelectedCountry(null);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Events" breadcrumbItem="Create New" />
          <Form id="createproject-form" onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}>

            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    <input type="hidden" className="form-control" id="formAction" name="formAction" defaultValue="add" />
                    <input type="hidden" className="form-control" id="project-id-input" />
                    <div className="mb-3">
                      <Label htmlFor="projectname-input">Event Name</Label>
                      <Input
                        id="eventName"
                        name="eventName"
                        type="text"
                        placeholder="Enter Event Name..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.eventName || ""}
                      />
                      {validation.touched.eventName && validation.errors.eventName ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventName}</FormFeedback>
                      ) : null}

                    </div>
                    <div className="mb-3">
                      <Label htmlFor="projectdesc-input">Event Description</Label>
                      
                       <Editor
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Place Your Content Here..."
                              name="eventDescription"
                              onEditorStateChange={onEditorStateChange}
                             
                            />
                      {validation.touched.eventDescription && validation.errors.eventDescription ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventDescription}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                    <Label htmlFor="projectdesc-input">Short Description</Label>
                      <Input as="textarea"
                        id="shortDescription"
                        rows={15}
                        multiple={true}
                        name="shortDescription"
                        placeholder="Enter Event Short Description..."
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.shortDescription || ""}
                      />
                       {validation.touched.shortDescription && validation.errors.shortDescription ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.shortDescription}</FormFeedback>
                      ) : null}
                    </div>

                   
                    <Row>
                      <Col lg={4}>
                      <div className="mb-3">
                      <Label className="form-label">Thumbnail Image</Label>
                      <p className="text-muted m-b-15">The Image should be min 561 × 691 pixel</p>

                      <div className="">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute bottom-0 end-0">
                            <Label htmlFor="project-image-input1" className="mb-0" id="projectImageInput">
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer shadow font-size-16">
                                  <i className="bx bxs-image-alt"></i>
                                </div>
                              </div>
                            </Label>
                            <UncontrolledTooltip placement="right" target="projectImageInput">
                              Select Image
                            </UncontrolledTooltip>
                            <input className="form-control d-none" id="project-image-input1" type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageChange(e, 'thumbImg')} />
                          </div>
                          <div className="avatar-lg upload-image-container">
                            <div className="avatar-title bg-light">
                              <img src={selectedThumbImg || ''} id="projectlogo-img" alt="" className="avatar-md h-auto" />
                            </div>
                          </div>
                        </div>
                        {validation.touched.thumbImg && validation.errors.thumbImg ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.thumbImg}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                      </Col>
                      <Col lg={4}>
                      <div className="mb-3">
                      <Label className="form-label">Banner Image Desktop</Label>
                      <p className="text-muted m-b-15">The Image should be min 2503 × 1367 pixel</p>


                      <div className="">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute bottom-0 end-0">
                            <Label htmlFor="project-image-input2" className="mb-0" id="projectImageInput1">
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer shadow font-size-16">
                                  <i className="bx bxs-image-alt"></i>
                                </div>
                              </div>
                            </Label>
                            <UncontrolledTooltip placement="right" target="projectImageInput1">
                              Select Image
                            </UncontrolledTooltip>
                            <input className="form-control d-none" id="project-image-input2" type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageChange(e, 'bannerImgDesktop')} />
                          </div>
                          <div className="avatar-lg upload-image-container">
                            <div className="avatar-title bg-light">
                              <img src={selectedBannerImgDesktop || ''} id="projectlogo-img" alt="" className="avatar-md h-auto" />
                            </div>
                          </div>
                        </div>
                        {validation.touched.bannerImgDesktop && validation.errors.bannerImgDesktop ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.bannerImgDesktop}</FormFeedback>
                        ) : null}
                      </div>
                    </div>
                      </Col>
                      <Col lg={4}>
                      <div className="mb-3">
                      <Label className="form-label">Banner Image Mobile</Label>
                      <p className="text-muted m-b-15">The Image should be min 1068 × 1367 pixel</p>

                      <div className="">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute bottom-0 end-0">
                            <Label htmlFor="project-image-input3" className="mb-0" id="projectImageInput">
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer shadow font-size-16">
                                  <i className="bx bxs-image-alt"></i>
                                </div>
                              </div>
                            </Label>
                            <UncontrolledTooltip placement="right" target="projectImageInput">
                              Select Image
                            </UncontrolledTooltip>
                            <input className="form-control d-none" id="project-image-input3" type="file" accept="image/png, image/gif, image/jpeg" onChange={(e) => handleImageChange(e, 'bannerImgMobile')} />
                          </div>
                          <div className="avatar-lg upload-image-container">
                            <div className="avatar-title bg-light">
                              <img src={selectedBannerImgMobile || ''} id="projectlogo-img" alt="" className="avatar-md h-auto" />
                            </div>
                          </div>
                        </div>
                        {validation.touched.bannerImgMobile && validation.errors.bannerImgMobile ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.bannerImgMobile}</FormFeedback>
                        ) : null}
                      </div>
                    </div>

                      </Col>
                      
                    </Row>
                    
                   
                    

                    <div className="mb-3">
                      <Label htmlFor="projectname-input">Event Category</Label>
                      <Select
                        value={selectedCategory}
                        placeholder="Select Category"
                        onChange={(data) => {
                          setSelectedCategory(data)
                          validation.setFieldValue("categoryId", data.value)

                        }}
                        options={categoryList}
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.categoryId && validation.errors.categoryId ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.categoryId}</FormFeedback>
                      ) : null}


                    </div>
                    <div className="mb-3">
                      <Label htmlFor="projectname-input">Organizer</Label>
                      <Select
                        value={selectedOrganizer}
                        placeholder="Select Organizer"
                        onChange={(data) => {
                          setSelectedOrganizer(data)
                          validation.setFieldValue("organizerId", data.value)

                        }}
                        options={organizerList}
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.organizerId && validation.errors.organizerId ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.organizerId}</FormFeedback>
                      ) : null}


                    </div>
                    <div className="mb-3">
                      <Label htmlFor="projectname-input">Ticket Type</Label>
                      <Select
                        value={selectedTicketType}
                        placeholder="Select Event Type"
                        onChange={(data) => {
                          setSelectedTicketType(data)
                          validation.setFieldValue("ticketType", data.value)

                        }}
                        options={
                          [
                            { value: "free", label: "Free" },
                            { value: "paid", label: "Paid" },

                          ]
                        }
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.ticketType && validation.errors.ticketType ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.ticketType}</FormFeedback>
                      ) : null}


                    </div>
                    {selectedTicketType?.value === "paid" && (
                      <div className="mb-3">
                        <Label htmlFor="projectname-input">Payment Channel</Label>
                        <Select
                          value={paymentChannel}
                          placeholder="Payment Channel"
                          onChange={(data) => {
                            setPaymentChannel(data)
                            validation.setFieldValue("paymentChannel", data.value)
                          }}
                          options={
                            [
                              { value: "our", label: "Our Channel" },
                              { value: "external", label: "External Channel" },

                            ]
                          }
                          width="100%"
                          className="select2-selection"
                        />
                        {validation.touched.paymentChannel && validation.errors.paymentChannel ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.paymentChannel}</FormFeedback>
                        ) : null}



                      </div>
                    )}
                    {paymentChannel?.value === "external" && selectedTicketType?.value === 'paid' && (
                      <div className="mb-3">
                        <Label htmlFor="projectname-input">Payment URL</Label>
                        <Input
                          id="projectname"
                          name="paymentUrl"
                          type="text"
                          placeholder="Enter Payment URL.."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.paymentUrl || ""}
                        />
                        {validation.touched.paymentUrl && validation.errors.paymentUrl ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.paymentUrl}</FormFeedback>
                        ) : null}

                      </div>
                    )}

                    <div className="mb-3">
                      <Label htmlFor="projectname-input">Event Type</Label>
                      <Select
                        value={selectedEventType}
                        placeholder="Select Event Type"
                        onChange={(data) => {
                          setSelectedEventType(data)
                          validation.setFieldValue("eventType", data.value)

                        }}
                        options={
                          [
                            { value: "online", label: "Online Event" },
                            { value: "offline", label: "Venue Event" },

                          ]
                        }
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.eventType && validation.errors.eventType ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventType}</FormFeedback>
                      ) : null}


                    </div>

                    <>

                    </>

                    {selectedEventType?.value === "online" && (
                      <div className="mb-3">
                        <Label htmlFor="projectname-input">What type of online event are you hosting?</Label>
                        <Select
                          value={onlineEventCategory}
                          placeholder="Select Online Event Type"
                          onChange={(data) => {
                            setOnlineEventCategory(data)
                            validation.setFieldValue("onlineEventType", data.value)

                          }}
                          options={
                            [
                              { value: 'standard_webinar', label: 'Standard Webinar' },
                              { value: 'training_workshop', label: 'Training and Workshop' },
                              { value: 'online_classes', label: 'Online Classes' },
                              { value: 'talk_show', label: 'Talk Show' },
                              { value: 'other', label: 'Other' },
                            ]
                          }
                          width="100%"
                          className="select2-selection"
                        />
                        {validation.touched.onlineEventType && validation.errors.onlineEventType ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.onlineEventType}</FormFeedback>
                        ) : null}


                      </div>
                    )}

                    <div>
                      <Label>Other Images Files</Label>
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          handleAcceptedFiles(acceptedFiles);
                          validation.setFieldValue("img", acceptedFiles[0])
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedFiles.map((file, index) => {
                          return (
                            <div className="border rounded" key={index}>
                              <div className="d-flex flex-wrap gap-2 p-2">
                                <div className="flex-shrink-0 me-3">
                                  <div className="avatar-sm bg-light rounded p-2">
                                    <img data-dz-thumbnail="" className="img-fluid rounded d-block" src={file.priview} alt={file.name} />
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className="pt-1">
                                    <h5 className="fs-md mb-1" data-dz-name>{file.path}</h5>
                                    <strong className="error text-danger" data-dz-errormessage></strong>
                                  </div>
                                </div>
                                <div className="flex-shrink-0 ms-3">
                                  <Button variant="danger" size="sm" onClick={() => {
                                    const newImages = [...selectedFiles];
                                    newImages.splice(index, 1);
                                    setSelectedFiles(newImages);
                                  }}>Delete</Button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {validation.errors.img && validation.touched.img ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.img}</FormFeedback>
                      ) : null}
                    </div>

                   
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <CardBody>
                    <h5 className="card-title mb-3">Publish</h5>
                    <div className="mb-3">
                      <Label htmlFor="project-status-input">Event Status</Label>
                      <Select
                        value={selectedStatus}
                        placeholder="Select Status"
                        onChange={(data) => {
                          setSelectedStatus(data)
                          validation.setFieldValue("eventStatus", data.value)

                        }}
                        options={[
                          { value: "active", label: "Active" },
                          { value: "inactive", label: "InActive" },

                        ]}
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.eventStatus && validation.errors.eventStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventStatus}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="project-status-input">Ticket Status</Label>
                      <Select
                        value={ticketStatus}
                        placeholder="Select Ticket Status"
                        onChange={(data) => {
                          setTicketStatus(data)
                          validation.setFieldValue("ticketStatus", data.value)
                        }}
                        options={[
                          { value: "open", label: "Open" },
                          { value: "closed", label: "Closed" },

                        ]}
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.ticketStatus && validation.errors.ticketStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.ticketStatus}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="project-status-input">Template</Label>
                      <Select
                        value={selectedTemplate}
                        placeholder="Select Template"
                        onChange={(data) => {
                          setSelectedTemplate(data)
                          validation.setFieldValue("templateName", data.value)

                        }}
                        options={[
                          { value: "entertainment", label: "Entertainment" },
                          { value: "corporate", label: "Corporate" },

                        ]}
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.templateName && validation.errors.templateName ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.templateName}</FormFeedback>
                      ) : null}
                    </div>


                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <h5 className="card-title mb-3">Event Date Details</h5>
                    <div className="mb-3">
                      <Label htmlFor="project-status-input">Event Date</Label>
                      <FlatPickr
                        className="form-control d-block"
                        id="orderdate"
                        name="eventDate"
                        placeholder="Select date"
                        options={{
                          mode: "single",
                          dateFormat: 'Y-m-d',
                        }}
                        onChange={(customerdate) => validation.setFieldValue("eventDate", moment(customerdate[0]).format("YYYY-MM-DD"))}
                        value={validation.values.eventDate || ''}
                      />
                      {validation.errors.eventDate && validation.touched.eventDate ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventDate}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="project-status-input">Event Time</Label>
                      <Select
                        value={selectedEventTime}
                        placeholder="Select Time"
                        onChange={(data) => {
                          setSelectedEventTime(data)
                          validation.setFieldValue("eventTime", data.value)
                        }}
                        options={eventTime}
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.eventTime && validation.errors.eventTime ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventTime}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="project-status-input">Event Duration</Label>
                      <Select
                        value={selectedEventDuration}
                        placeholder="Select Duration"
                        onChange={(data) => {
                          setSelectedEventDuration(data)
                          validation.setFieldValue("eventDuration", data.value)

                        }}
                        options={eventDuration}
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.eventDuration && validation.errors.eventDuration ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventDuration}</FormFeedback>
                      ) : null}
                    </div>


                  </CardBody>
                </Card>
              
                  <Card>
                    <CardBody>
                      <h5 className="card-title mb-3">Event Location Details</h5>
                      {selectedEventType?.value === "offline" && (
                      <>
                      <div className="mb-3">
                        <Label htmlFor="projectname-input">Event Google Location</Label>
                        <Input
                          id="projectname"
                          name="eventLocation"
                          type="text"
                          placeholder="Enter google Location..."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.eventLocation || ""}
                        />
                        {validation.touched.eventLocation && validation.errors.eventLocation ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.eventLocation}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="projectname-input">Event Venue</Label>
                        <Input
                          id="projectname"
                          name="eventVenue"
                          type="text"
                          placeholder="Enter the event venue .."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.eventVenue || ""}
                        />
                        {validation.touched.eventVenue && validation.errors.eventVenue ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.eventVenue}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="projectname-input">Address</Label>
                        <Input
                          id="projectname"
                          name="eventAddress1"
                          type="text"
                          placeholder="Enter the address .."
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.eventAddress1 || ""}
                        />
                        {validation.touched.eventAddress1 && validation.errors.eventAddress1 ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.eventAddress1}</FormFeedback>
                        ) : null}

                      </div>
                      </>
                      )}
                      <div className="mb-3">
                        <Label htmlFor="projectname-input">Country</Label>
                        <Select
                          value={selectedCountry}
                          placeholder="Select country"
                          onChange={(data) => {
                            setSelectedCountry(data)
                            validation.setFieldValue("eventCountry", data.value)

                          }}
                          options={countryList}
                          width="100%"
                          className="select2-selection"
                        />
                        {validation.touched.eventCountry && validation.errors.eventCountry ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.eventCountry}</FormFeedback>
                        ) : null}


                      </div>
                    </CardBody>
                  </Card>
                  {selectedTicketType?.value === "paid" &&paymentChannel?.value==="our"&& (
                  <Card>
                  <CardBody>
                  <h5 className="card-title mb-3">Pricing Details</h5>
                  <div className="mb-3">
                        <Label htmlFor="projectname-input">Processing Fee</Label>
                        <Input
                          id="processingFee"
                          name="processingFee"
                          type="text"
                          placeholder="Enter zero if no processing fee"

                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.processingFee || ""}
                        />
                        {validation.touched.processingFee && validation.errors.processingFee ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.processingFee}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                        <Label htmlFor="projectname-input">Ticket Fee</Label>
                        <Input
                          id="ticketFee"
                          name="ticketFee"
                          type="text"
                          placeholder="Enter zero if no ticket fee"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.ticketFee || ""}
                        />
                        {validation.touched.ticketFee && validation.errors.ticketFee ? (
                          <FormFeedback type="invalid" className="d-block">{validation.errors.ticketFee}</FormFeedback>
                        ) : null}

                      </div>
                      <div className="mb-3">
                      <Label htmlFor="project-status-input">Show Processing & ticket fees in the checkout form and ticket  </Label>
                      <Select
                        value={otherFeesSelected}
                        placeholder="Select "
                        onChange={(data) => {
                          setOtherFeesSelected(data)
                          validation.setFieldValue("showOtherFees", data.value==='yes'?true:false)  

                        }}
                        options={[
                          { value: "yes", label: "Yes" },
                          { value: "no", label: "No" },

                        ]}
                        width="100%"
                        className="select2-selection"
                      />
                      {validation.touched.eventStatus && validation.errors.eventStatus ? (
                        <FormFeedback type="invalid" className="d-block">{validation.errors.eventStatus}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="projectname-input">Ticket Grouping</Label>
                      <Select
                        value={ticketGrouping}
                        placeholder="Select Ticket Grouping"
                        onChange={(data) => {
                          setTicketGrouping(data)
                          validation.setFieldValue("ticketGrouping", data.value)
                        }}
                        options={[
                          { value: "single", label: "Single" },
                          { value: "group", label: "Group" },

                        ]}
                        width="100%"
                        className="select2-selection"
                      />
                    </div>
                  </CardBody>
                  </Card>)}
                
              </Col>
              <Col lg={8}>
                <div className="text-end mb-4">
                  <Button type="submit" disabled={isLoading} color="primary">{isLoading ? 'Creating event...' : 'Create Event'}</Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateEvent;
