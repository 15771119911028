import React, { useEffect, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import TableContainer from "../../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
 
} from "reactstrap";
import toast from 'react-hot-toast';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import Select from "react-select";

//redux
import Spinners from "components/Common/Spinner";
import {  deleteEvent, deleteUser, getAllCountries, getAllEventByOrganizer, getAllEvents, getAllOrganizer, getEventByQuery, updateUser } from "api";
import { handleError } from "utils/errorHandler";
import moment from "moment";

const EventList = () => {

  const navigate = useNavigate();

  //meta title
  document.title = "EventList List | EventoX";

  const [eventList, setEventList] = useState([])
  const [organizerList, setOrganizerList] = useState([])
  const [selectedOrganizer, setSelectedOrganizer] = useState(null)
  const [contact, setContact] = useState();
  const [isLoading, setIsLoading] = useState(false)
  const [event, setEvent] = useState([])

  useEffect(() => {
    
    fetchAll();
    return () => {

    }
  }, [])

  const fetchAll=async()=>{
    try {
      
      const respAllEvent = await getAllEvents();
      let orgRes = await getAllOrganizer();
      const resOrganizerOptions = orgRes.map((item) => {
        return {
          value: item.organizerId,
          label: item.name
        }})

      setOrganizerList([{
        label: "All",
        value: "all"
      },...resOrganizerOptions,])
      setEventList(respAllEvent)
    } catch (error) {
      console.log(error);
    }
  }
  const fetchEventByOrganizer = async (organizerId) => {
    try {
      if(organizerId === "all"){
        const resp = await getAllEvents();
        setEventList(resp)
      }else{
      const resp = await getEventByQuery("organizerId",organizerId);
      setEventList(resp)
      }
    } catch (error) {
      console.log(error);
    }
  }
  const viewEvent = async (eventData) => {
      window.open('https://qa.eventox.co/event/'+eventData.slugId, '_blank');
  }
  const handleTicketClick = (eventData) => {
    navigate("/ticket-by-event/" + eventData.slugId)
  }
  const columns = useMemo(
    () => [
      {
        header: "#",
        accessorKey: "thumbImg",
        cell: (cell) => (
          <>
            {!cell.getValue() ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">{cell.row.original.eventName.charAt(0)} </span>
              </div>
            ) : (
              <div>
                <img className="rounded-circle avatar-xs" src={cell.getValue()} alt="" />
              </div>
            )}
          </>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Event Name',
        accessorKey: 'eventName',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return (
            <>
              <h5 className='font-size-14 mb-1'>
                <Link to='#' className='text-dark'>{cell.getValue()}</Link>
              </h5>
              <p className="text-muted mb-0">{`${cell.row.original.eventVenue},${cell.row.original?.Country?.countryName}`}</p>
              <p className="text-muted mb-0">{moment(cell.row.original.eventDate).format('MMMM DD, yy')}</p>
            </>
          )
        }
      },
      
      {
        header: 'Organizer',
        accessorKey: 'Organizer.name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Category',
        accessorKey: 'Category.categoryName',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Ticket Type',
        accessorKey: 'ticketType',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Event Type',
        accessorKey: 'eventType',
        enableColumnFilter: false,
        enableSorting: true,
      },
     
      {
        header: 'Status',
        accessorKey: 'eventStatus',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell) => {
          return (
            <div>
              {

                <span className="badge badge-soft-primary font-size-11 m-1">{cell.getValue()}</span>

              }
            </div>
          );
        },
      },
      {
        header: 'Action',
        cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle tag="a" className="card-drop">
                <i className="mdi mdi-dots-horizontal font-size-18"></i>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-end">
                {/* <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                  }
                  }
                >
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem> */}
                {/* {cellProps.row.original.ticketType === "paid"&&cellProps.row.original.paymentChannel === "our" && (
                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleTicketClick(customerData);
                  }
                  }
                >
                  <i className="mdi mdi-plus font-size-16 text-success me-1" id="edittooltip"></i>
                  Add Ticket Details
                  <UncontrolledTooltip placement="top" target="edittooltip">
                  Add Ticket Details
                  </UncontrolledTooltip>
                </DropdownItem>
              )} */}
                {/* <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickDelete(customerData);
                  }}>
                  <i className="mdi mdi-trash-can font-size-16 text-danger me-1" id="deletetooltip"></i>
                  Delete
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </DropdownItem> */}
                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleEditEvent(customerData);
                  }}>
                  <i className="mdi mdi-pencil font-size-16 text-success me-1" id="edittooltip"></i>
                  Edit
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    const data = cellProps.row.original;
                    viewEvent(data);
                  }
                  }
                >
                  <i className="mdi mdi-eye font-size-16 text-success me-1" id="edittooltip"></i>
                  View Details
                  <UncontrolledTooltip placement="top" target="edittooltip">
                  View Details
                  </UncontrolledTooltip>
                </DropdownItem>
               
                <DropdownItem
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                  }
                  }
                >
                  <i className="mdi mdi-plus font-size-16 text-success me-1" id="edittooltip"></i>
                  Add Additional Details
                  <UncontrolledTooltip placement="top" target="edittooltip">
                  Add Additional Details
                  </UncontrolledTooltip>
                </DropdownItem>
               
                
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }
      },
    ],
    []
  );
  const handleClickNewEvent = () => {
    navigate("/event/new")
  }






  //delete customer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (eventData) => {
    setEvent(eventData);
    setDeleteModal(true);
  };
  const handleEditEvent = (eventData) => {
    navigate("/admin/events/edit/" + eventData.eventId)
  }

  const handleDeleteEvent = async() => {
    if (event && event.eventId) {
      try {
      
       const resp = await deleteEvent(event.eventId);
       toast.success("Delete event successfully");
       fetchAll()
      } catch (error) {
        toast.error("Error Deleting event " + error?.message);
        handleError(error)
      }

    }
    setDeleteModal(false);
  };

 
 
  


  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Dashboard" breadcrumbItem="Events List" />

          <Row>
            {
              isLoading ? <Spinners setLoading={setIsLoading} />
                :
                <Col lg="12">

                  <Card>
                    <CardBody>
                      <Row className="mb-3">
                        <Col xl="5">
                          <div className="col-md-12">
                            <Select
                              value={selectedOrganizer}
                              placeholder="Select Organizer"
                              onChange={(data) => {
                                fetchEventByOrganizer(data.value)
                                setSelectedOrganizer(data)
                              }}
                              options={organizerList}
                              width="100%"
                              className="select2-selection"
                            />

                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <TableContainer
                          columns={columns}
                          data={eventList || []}
                          isGlobalFilter={true}
                          isPagination={true}
                          SearchPlaceholder="Search..."
                          isCustomPageSize={true}
                          isAddButton={true}
                          handleUserClick={handleClickNewEvent}
                          buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal mb-2"
                          buttonName="New Event"
                          tableClass="align-middle table-nowrap table-hover dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                          theadClass="table-light"
                          paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                          pagination="pagination"
                        />
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
            }

          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(EventList);
